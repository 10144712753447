import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["list", "nextButton", "prevButton", "dot"];

  static values = { currentSet: Number, itemsPerSet: Number };

  connect() {
    this.currentSetValue = 0;
    this.itemsPerSetValue = 4;
    this.updateButtonState();
    this.setListItemWidth();
    this.scrollToActiveSet();
  }

  calculateTotalSets() {
    return Math.ceil(this.listTargets[0].children.length / this.itemsPerSetValue);
  }

  transformItems(index, sets){
    return -index * (100 / sets) + "%"
  }

  animateToItems(val){
    if(window.innerWidth < 1024){
      this.listTargets[0].style.transition = "transform 0.5s ease-in-out";
      this.listTargets[0].style.transform = "translateX(" + val + ")";
    }
  }

  updateButtonState() {
    const totalSets = this.calculateTotalSets();
    this.nextButtonTarget.disabled = this.currentSetValue === totalSets - 1;
    this.prevButtonTarget.disabled = this.currentSetValue === 0;
  }

  updateDots(activeSetIndex) {
    this.dotTargets.forEach((dot, index) => {
      dot.classList.toggle("active", index === activeSetIndex);
    });
  }

  updateButtons(index){
    setTimeout(() => {
      this.listTargets[0].style.transition = "";
      this.updateButtonState();
      this.updateDots(index);
    }, 500);
  }

  changeSet(direction) {
    const totalSets = this.calculateTotalSets();
    this.currentSetValue = (this.currentSetValue + direction + totalSets) % totalSets;
    const transformValue = this.transformItems(this.currentSetValue, totalSets);
    this.animateToItems(transformValue);
    this.updateButtons(this.currentSetValue);
  }

  goToSet(setIndex) {
    const direction = setIndex > this.currentSetValue ? 1 : 0;
    this.currentSetValue = setIndex;
    this.changeSet(direction);
  }

  scrollToActiveSet() {
    const activeItem = this.listTargets[0].querySelector(".is-active");
    if (activeItem) {
      const setIndex = Math.floor(Array.from(this.listTargets[0].children).indexOf(activeItem) / this.itemsPerSetValue);
      this.currentSetValue = setIndex;
      const totalSets = this.calculateTotalSets();
      const transformValue = this.transformItems(setIndex, totalSets);
      this.animateToItems(transformValue);
      this.updateButtons(setIndex);
    }
  }
  
  setListItemWidth() {
    const viewportWidth = window.innerWidth;
    const isSmallScreen = viewportWidth < 1024;
    const totalSets = this.calculateTotalSets();
    for (let setIndex = 0; setIndex < totalSets; setIndex++) {
      for (let itemIndex = 0; itemIndex < this.itemsPerSetValue; itemIndex++) {
        const globalIndex = setIndex * this.itemsPerSetValue + itemIndex;
        const item = this.listTargets[0].children[globalIndex];
        if (item) {
          if (isSmallScreen) {
            const listItemWidthPx = viewportWidth * (100 / this.itemsPerSetValue) / 100;
            item.style.width = listItemWidthPx + "px";
          }
        }
      }
      const dot = this.dotTargets[setIndex];
      if (dot) {
        dot.addEventListener("click", () => {
          this.goToSet(setIndex);
        });
      }
      const lastItemIndex = this.listTargets[0].children.length - 1;
      const lastItem = this.listTargets[0].children[lastItemIndex];
      if (lastItem && lastItem.classList.contains("is-active")) {
        this.listTargets[0].style.paddingBottom = "20px";
      }
    }
  }

  nextSet() {
    this.changeSet(1);
  }

  prevSet() {
    this.changeSet(-1);
  }
}
