import ApplicationController from "../../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = [ 
    "selectInput", 
    "selectDropdown", 
    "visibleSelection", 
    "placeholder", 
    "selectOption", 
    "pillTemplate", 
    "selectedPill",
    "noMatchMsg"
  ]
  static values = { 
    hasSearch: Boolean, 
    isOpen: Boolean 
  }

  initialize() {
    // Render select box pills if selected (like on edit)
    if (this.hasAnySelections()) this.renderExistingSelectPills();
  }
  
  toggleDropdownOptions() {
    if (this.isOpenValue) return this.hide();
    this.show();
    this.setCloseListener();
  }

  show() {
    this.selectDropdownTarget.classList.remove("animate-slide-and-fade-out-up-lesser");
    this.selectDropdownTarget.classList.remove("vanish");
    this.selectDropdownTarget.classList.add("animate-slide-and-fade-in-down-lesser");
    this.isOpenValue = true;
  }

  hide() {
    this.isOpenValue = false;
    this.selectDropdownTarget.classList.add("vanish");
  }

  // Close select dropdown when user clicks out
  setCloseListener() {
    document.addEventListener("click", (e) => {
      if (!this.element.contains(e.target)) {
        this.selectDropdownTarget.classList.remove("animate-slide-and-fade-in-down-lesser");
        this.selectDropdownTarget.classList.add("animate-slide-and-fade-out-up-lesser");
        setTimeout(() => {
          this.hide();
        }, 500);
      }
    });
  }

  // If there's a selection and .has-errors has been added by checkMultiselectsValidity(), removes it.
  removeErrors() {
    if (this.hasAnySelections()) this.element.classList.remove("has-errors");
  }

  // Value inside dropdown clicked
  selectValue(e) {
    this.selection = e.currentTarget;
    const selectedPill = this.selection.nextElementSibling; // Grabs hidden div that is pill so it can be appended to visible select box
    this.clickedOptionValue = this.selection.dataset.value;
    this.selectedOption = this.selectInputTarget.options[this.clickedOptionValue];
    
    if (this.selection.dataset.selected == "true") {
      this.selection.dataset.selected = "false";
      this.selectedOption.selected = false;
      this.removeSelectedPill(this.clickedOptionValue, null);
    } else {
      this.selection.dataset.selected = "true";
      this.selectedOption.selected = true;
      this.renderSelectPill(selectedPill);
    }

    this.removeErrors();
    this.removeOrReplacePlaceholder();
  }
  
  // pill inside of select box clicked
  removeValue(e) {
    e.stopPropagation();
    const selectedPill = e.currentTarget;
    this.clickedOptionValue = selectedPill.dataset.value;
    this.selectedOption = this.selectInputTarget.options[this.clickedOptionValue];
    this.selectedOption.selected = false;

    this.selectOptionTargets.forEach(option => { 
      if (option.dataset.value == this.clickedOptionValue) option.dataset.selected = "false";
    })

    this.removeSelectedPill(this.clickedOptionValue, selectedPill)
    this.removeOrReplacePlaceholder();
  }

  removeSelectedPill(value, selectedPill) {
    const pill = selectedPill || this.selectedPillTargets.find(pill => pill.dataset.value == value);
    pill.classList.remove("animate-fade-in");
    pill.classList.add("animate-fade-out");
    setTimeout(() => {
      pill.remove();
    }, 450);
  }

  renderSelectPill(template) {
    this.pill = template.cloneNode(true)
    this.pill.id = this.pill.id.replace(/\-template$/, "")

    this.pill.classList.remove('vanish');
    this.pill.classList.remove('multiselect-dropdown-pill-template');
    this.pill.classList.add('multiselect-dropdown-pill');
    this.pill.setAttribute('data-forms--multiselect--multiselect-component-target', 'selectedPill');
    this.visibleSelectionTarget.append(this.pill);
  }

  renderExistingSelectPills() {
    this.selectOptionTargets.forEach((option) => {
      if (option.dataset.selected == "true") this.renderSelectPill(option.nextElementSibling);
    })
  }

  removeOrReplacePlaceholder() {
    if (this.hasAnySelections()) return this.placeholderTarget.classList.add("vanish");
    setTimeout(() => {
      this.placeholderTarget.classList.remove("vanish");
    }, 450) // Delay so animation can finish
  }

  hasAnySelections() {
    return this.selectInputTarget.selectedOptions.length > 0;
  }

  search(e) {
    const searchValue = e.target.value.toUpperCase();
    let optionValue;
    let hasMatch = false;

    this.selectOptionTargets.forEach((option) => {
      optionValue = option.textContent || option.innerText;
      if (optionValue.toUpperCase().indexOf(searchValue) > -1) {
        option.style.display = "";
        hasMatch = true;
      } else {
        option.style.display = "none";
      }
    });

    if (hasMatch) return this.noMatchMsgTarget.classList.add("vanish");
    this.noMatchMsgTarget.classList.remove("vanish")
  }
}
// Import this into the form's controller if it has required multiselects
// Generate errors for required selects using multiselect-component
export function checkMultiselectsValidity(showToastIfInvalid = true) {
  const multiselectComponents = document.querySelectorAll('.multiselect-component');
  let showToast = false;
  multiselectComponents.forEach(component => {
    const select = component.querySelector('select');
    if (select.hasAttribute('required') && !select.value) {
      component.classList.add("has-errors");
      if (showToastIfInvalid) showToast = true;
    }
  })
  return showToast;
}
