import { limitInputChar } from "../utilities/input_limiter";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ 
    "form", 
    "nonLandFieldsContainer", 
    "nonLandField", 
    "interiorSqrFtInput", 
    "propTypeInput",
    "mlsListedInput",
    "renovationNeededInput",
    "yearBuiltInput"
  ];

  initialize() {
    new Cleave(this.interiorSqrFtInputTarget, {
      numeral: true,
      swapHiddenInput: true,
      numeralDecimalScale: 0,
      prefix: ' sqft',
      tailPrefix: true,
      rawValueTrimPrefix: true
    });
    limitInputChar(this.yearBuiltInputTarget, 4);

    // On edit or back, hide fields
    if (this.propTypeInputTarget.value == "land") this.hideNonLandFields();
  }

  connect() {
    super.connect();
    super.generateInputId('.property_interior_sqft', 'property-interior-sqft');
  }

  updateRenovationNeededBoolean(e) {
    if (e.target.options[e.target.options.selectedIndex].textContent == "No Renovation Required") {
      this.renovationNeededInputTarget.value = "false";
      return;
    }
    this.renovationNeededInputTarget.value = "true";
  }
  
  hideNonLandFields() {
    this.nonLandFields = this.nonLandFieldsContainerTarget.querySelectorAll('input, select');
    if (this.propTypeInputTarget.value == "land") {
      super.addVanish(this.nonLandFieldsContainerTarget);
      this.nonLandFields.forEach((input) => {
        input.removeAttribute('required')
    });
      return;
    }
    super.removeVanish(this.nonLandFieldsContainerTarget);
    this.nonLandFields.forEach((input) => input.setAttribute('required', 'required'));
  }

  updateMlsListedBoolean(e) {
    if (e.target.options[e.target.options.selectedIndex].textContent == "MLS (Non-eXp Listing)" || e.target.options[e.target.options.selectedIndex].textContent == "MLS (eXp Listing)") {
      this.mlsListedInputTarget.value = "true"
      return;
    }
    this.mlsListedInputTarget.value = "false"
  }

  checkFormValidity(e) {
    e.preventDefault();

    let isValid = true;
    
    if (this.propTypeInputTarget.value != "land" && this.interiorSqrFtInputTarget.value === " sqft") {
      super.toggleInputError(
        this.interiorSqrFtInputTarget, 
        "Please enter interior square footage"
      )
        isValid = false;
    }
    if (isValid) return this.stimulate("PropertiesStepperReflex#render_step_three");
  }

}