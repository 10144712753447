import ApplicationController from "./application_controller";
import {checkValidity} from "./buy_box_select_controller";

export default class extends ApplicationController {
  static targets = [
    "selectInputContainer",
    "selectInput",
    "buyBoxSelectContainer",
    "buyBoxModalBtn",
    "buyBoxSelect",
    "buyBoxModal"
  ]
  static values = {
    newPage: Boolean
  }

  openModal() {
    this.buyBoxModalTarget.classList.add("is-active");
  }

  toggleAccessibility() {
    this.selectInputTarget.value = "";
    this.buyBoxSelectTargets.forEach(buyBoxSelect => {
      buyBoxSelect.classList.remove('is-selected');
      buyBoxSelect.dataset.buyBoxSelectIsSelectedValue = false;
    });
    this.selectInputContainerTarget.classList.toggle("vanish");
    this.buyBoxSelectContainerTarget.classList.toggle("vanish");
    this.checkValidity();
  }

  checkValidity() {
    if (!this.newPageValue) return; // Don't check on edit/submit more
    checkValidity(this.selectInputTarget, this.buyBoxModalBtnTarget);
  }

  // For handling response using turbo stream
  handleResponse(event) {
    const status = event.detail.fetchResponse.response.status

    if(event.detail.success) {
      this.handleSuccess(event);
    } else {
      this.handleError(event);
    }
  }

  async handleSuccess(event) {
    event.preventDefault();
  }

  async handleError(event) {
    let formattedErrors = "";

    if (event.detail && event.detail.fetchResponse) {
      const fetchResponse = event.detail.fetchResponse;

      const text = await fetchResponse.response.text();
      const jsonResponse = JSON.parse(text);
      formattedErrors = jsonResponse.errors.join("<br>");
    }

    super.renderFlash(
      `Please fix the errors listed below and try again. <br> <b> ${formattedErrors}</b>`,
      "alert",
      "true"
    );
  }
}
