import ApplicationController from "./application_controller";
import {listenForEsc} from "../../components/design/modal/modal_controller";

export default class extends ApplicationController {
  static targets = [ "notMySellerInput",
                      "disclosureInput",
                      "agreementInput",
                      "countyNameInput",
                      "countySelect",
                      "checkbox",
                      "submitBtn",
                      "form",
                      "agreementCard",
                      "modalSubmitBtn",
                      "disclosureModalBtn",
                      "referrersNameContainer",
                      "referrersNameInput",
                      "referralSourceSelect"
                    ]
  static values = {
    propertyId: String
  }
  initialize() {
    this.modalSubmit = false;
    this.isEdit = false;

    // Makes sure modal and everything works on edit/previous page
    if (this.disclosureInputTarget.dataset.sellerDisclosureAttached == "true" || this.disclosureInputTarget.value == "true") {
      this.disclosureModalBtnTarget.classList.remove("vanish");
      this.submitBtnTarget.classList.remove("vanish");
      this.modalSubmitBtnTarget.removeAttribute("disabled");
      this.submitBtnTarget.parentElement.style.marginLeft = "1rem"
      this.modalSubmit = true;
    }
    this.toggleReferrerNameVisibility();
    this.updateCountyNameInput();

    // On edit/previous make sure agreement cards display properly
    this.agreementInputTargets.forEach((input) => {
      if (input.value == "true") {
        this.updateCheckboxStyles(document.getElementById(`${input.dataset.checkboxId}`));
        this.isEdit = true;
      }
      if (this.isEdit) this.enableFormStepTwo();
    });
  }

  showAgreementModal(e) {
    const modal = document.getElementById(`modal-component-${e.target.dataset.modalId}`);
    modal.classList.add("is-active");
    listenForEsc(modal);
  }

  toggleReferrerNameVisibility() {
    if (this.referralSourceSelectTarget.value == "Other") {
      this.showReferrerNameInput();
      return;
    }
    this.referrersNameInputTarget.removeAttribute("required");
    super.addVanish(this.referrersNameContainerTarget);
  }

  showReferrerNameInput() {
    this.referrersNameInputTarget.setAttribute("required", true);
    super.removeVanish(this.referrersNameContainerTarget);
    this.referrersNameInputTarget.focus();
  }

  selectCheckboxInput(e) {
    this.input = document.getElementById(e.target.dataset.inputId);
    this.updateCheckboxStyles(e.target);
    this.updateInputValues();
    this.enableFormStepTwo();
  }

  updateCheckboxStyles(element) {
    this.checkboxTargets.forEach((el) => {
      el.classList.remove("is-selected");
      el.title = "Select to agree";
      el.classList.add("one-is-selected");
    });

    element.classList.add("is-selected");
    element.title = "You have agreed to this";
  }

  updateInputValues() {
    this.agreementInputTargets.forEach((input) => input.value = "false");
    this.input.value = "true";
    if (this.input == this.disclosureInputTarget) return this.hideSubmitShowModal();
    this.showSubmitFormBtn();
  }

  showSubmitFormBtn() {
    super.removeVanish(this.submitBtnTarget);
    super.addVanish(this.disclosureModalBtnTarget);
  }

  hideSubmitShowModal() {
    super.removeVanish(this.disclosureModalBtnTarget);
    super.addVanish(this.submitBtnTarget);
  }

  enableFormStepTwo() {
    this.submitBtnTarget.removeAttribute('data-action');
  }

  triggerUploadDocModal() {
    this.modalSubmit = true;
    document.getElementById(`modal-component-seller-disclosure-upload-doc-modal`).classList.add("is-active");
  }

  showFormIncompleteWarningToast(e) {
    e.preventDefault();

    super.renderToast(this.formTarget.id, "Please select one of the agreements", 'warning');
    this.agreementCardTarget.classList.add("animate-pulse"); // Bring attention to agreement cards
    setTimeout(() => this.agreementCardTarget.classList.remove("animate-pulse"), 2000);
  }

  verifySubmitBtn(e) {
    if (e.target.value) return this.modalSubmitBtnTarget.removeAttribute("disabled");
    this.modalSubmitBtnTarget.setAttribute("disabled", true)
  }

  updateCountyNameInput() {
    this.countyNameInputTarget.value = (this.countySelectTarget.value == '') ? '' :
      this.countySelectTarget.selectedOptions[0].textContent;
  }

  onSubmitSuccess(event) {
    event.preventDefault();

    // propertyIdValue will be present if this is an edit/previous page
    const propId = event.detail[0]?.property?.id || this.propertyIdValue;

    if (this.modalSubmit) super.closeModal();

    this.stimulate("PropertiesStepperReflex#render_step_two", propId);
  }

  onSubmitError(event) {
    const [data, _status, xhr] = event.detail;
    const formattedErrors =  data.errors.join("<br>")

    if (this.modalSubmit) super.closeModal();
    super.renderFlash(`Please fix the errors listed below and try again.  <br> <b> ${formattedErrors}</b>`, "alert", "true");
  }

  // For handling response using turbo stream
  handleResponse(event) {
    const status = event.detail.fetchResponse.response.status

    if(status == 201) {
      this.handleSuccess(event);
    } else {
      this.handleError(event);
    }
  }

  async handleSuccess(event) {
    event.preventDefault();

    let propertyId;

    if (event.detail && event.detail.fetchResponse) {
      const fetchResponse = event.detail.fetchResponse;

      const text = await fetchResponse.response.text();
      const jsonResponse = JSON.parse(text);
      propertyId = jsonResponse.property.id;
    }

    const propId = propertyId || this.propertyIdValue;

    if (this.modalSubmit) super.closeModal();

    this.stimulate("PropertiesStepperReflex#render_step_two", propId);
  }

  async handleError(event) {
    let formattedErrors = "";

    if (event.detail && event.detail.fetchResponse) {
      const fetchResponse = event.detail.fetchResponse;

      const text = await fetchResponse.response.text();
      const jsonResponse = JSON.parse(text);
      formattedErrors = jsonResponse.errors.join("<br>");
    }

    if (this.modalSubmit) super.closeModal();
    super.renderFlash(`Please fix the errors listed below and try again.  <br> <b> ${formattedErrors}</b>`, "alert", "true");
  }
}
