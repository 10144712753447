import { Controller } from "stimulus"

export default class extends Controller {
  static values = { urlTemplate: String, frame: String }

  visitNewUrl(event) {
    let url = null;
    if(this.frameValue === "property_regions_input") {
      url = this.urlTemplateValue.replace("state_abbr", event.target.value)

      const selectedRegionId = document.querySelector(
        "#property_region_id"
      ).value;
      url = url.replace("region_id_value", selectedRegionId);
    } else {
      url = this.urlTemplateValue
    }

    Turbo.visit(url, { frame: this.frameValue })
  }
}
