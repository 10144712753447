import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    id: String,
    isSelected: Boolean,
    newPage: Boolean,
    alreadySubmitted: Boolean
  }

  static targets = ["buyBoxSelect", "reviewBtn"]

  initialize() {
    this.selectElement = document.getElementById('buy-box-ids-input');
    this.submitBtnElement = document.getElementById('modal-btn');
  }

  selectBuyBox(event) {
    const classListToIgnore = ['show-more-less', 'tabs'];
    let targetElement = event.target;
    while (targetElement) {
      if (classListToIgnore.some(className => targetElement.classList.contains(className))) {
          return;
      }
      targetElement = targetElement.parentElement;
    }
    let parentElementClasses = event.target.closest('.select-buy-box-btn').classList;
    if (!this.newPageValue && this.alreadySubmittedValue) return super.renderToast("#buy-boxes-container", "You cannot deselect a Buy Box once submitted.")
    this.isSelectedValue = !this.isSelectedValue;
    this.isSelectedValue ? parentElementClasses.add('buybox-selected') : parentElementClasses.remove('buybox-selected');
    this.element.firstElementChild.scroll(0, 0);
    this.selectElement.querySelector(`option[value="${this.idValue}"]`).selected = this.isSelectedValue;
    if (this.newPageValue) checkValidity(this.selectElement, this.submitBtnElement);
  }

  enableReview() {
    // If any buyBoxSelectTarget is checked, enable the review button
    if (this.buyBoxSelectTargets.some(buyBoxSelect => buyBoxSelect.checked)) {
      this.reviewBtnTarget.removeAttribute("disabled");
      this.reviewBtnTarget.textContent = "Review";
      return;
    }

    // If no buyBoxSelectTarget is checked, disable the review button
    this.reviewBtnTarget.setAttribute("disabled", "disabled");
    this.reviewBtnTarget.textContent = "Select Buy Boxes";
  }
}
export const checkValidity = (select, submitBtn) => {
  if (select.selectedOptions.length > 0) {
    submitBtn.removeAttribute("disabled");
    submitBtn.textContent = "Review";
    return;
  }
  submitBtn.setAttribute("disabled", "disabled");
  submitBtn.textContent = "Select Buy Boxes";
}
