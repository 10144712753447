// Rendered from _flash_msg.html.erb partial on connect (right when partial is rendered automatically by controller flash assignment) to show a dismissable flash message.
// Dedicated to Flash Messages, for toast messages visit /toasts
import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static values = {type: String};

  connect() {
    this.element.classList.remove("vanish");
    listenDismissClick(this.element);
    if (this.typeValue.match(/timed/g)) this.waitThenDismiss(); //_timed is in the flash key EG: "flash[:notice_timed]"
  }

  closeFlash() {
    closeFlash(this.element)
  }

  waitThenDismiss() {
    setTimeout(() => {
      this.closeFlash();
    }, 5000);
  }
}

function listenDismissClick(flash) {
  document.addEventListener("click", (e) => {
    if (!flash.contains(e.target)) closeFlash(flash);
  })
}

function closeFlash(flash) {
  flash.classList.add("disappear");
  setTimeout(() => {
    flash.remove();
  }, 1000);
}