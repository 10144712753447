import ApplicationController from "../../../javascript/controllers/application_controller";
export default class extends ApplicationController {
  // Resource target refers to the element loading in.
  // Loader target is the spin or skeleton loader that will be removed once resource is loaded in.
  static targets = [ "resource", "loader" ]

  connect() {
    // Check if the resource is already loaded when the controller connects (from cache)
    // In that case data-action='load->loaders--load#removeLoader' will not be called
    if (this.hasResourceTarget && this.resourceTarget.complete) {
      this.removeLoader();
    }
  }

  // works off resources' data-action='load->loaders--load#removeLoader'
  removeLoader() {
    if (this.hasLoaderTarget) this.loaderTarget.remove()
  }
}
