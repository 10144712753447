import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["amountInput", "rangeInput"];

  connect() {
    super.connect();
    this.calculateInitialOfferRange();
  }

  calculateInitialOfferRange() {
    const amountInputVal = this.amountInputTarget.value;
    if (!amountInputVal) {
      this.rangeInputTarget.value = "";
      return;
    }

    const amount = parseInt(amountInputVal.replace(/[^0-9]/g, ""));
    const range = this._calculateInitialOfferRangeFromAmount(amount);
    this.rangeInputTarget.value = range;
  }

  _calculateInitialOfferRangeFromAmount(amount) {
    const rangeLower = Math.round(amount * 0.85).toLocaleString(
      "en-US"
    );
    const rangeUpper = Math.round(amount * 1.15).toLocaleString(
      "en-US"
    );

    return `${rangeLower} - ${rangeUpper}`;
  }
}
