import { Controller } from "stimulus";
import consumer from "../channels/consumer";

export default class extends Controller {
  static targets = ["messages", "newMessage", "textarea"];

  connect() {
    this.subscription = consumer.subscriptions.create(
      { channel: "RoomChannel", id: this.data.get("id") },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription);
  }

  _connected() {}

  _disconnected() {}

  _received(data) {
    if (data.like_counter) {
      document.querySelector(data.selector).innerHTML = data.like_counter;
      return
    }

    if (data.message) {
      this.messagesTarget.insertAdjacentHTML("beforeend", data.message);
      const noMessagesText = document.getElementById("chatroom-no-messages-text");
      if (noMessagesText) noMessagesText.remove();
      if (!document.hidden) {
        this.subscription.perform("touch");
      }
    }
  }

  clearMessage(event) {
    if (event.detail.success) {
      this.newMessageTarget.value = "";
    }
  }

}
