import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    numeral: { type: Boolean, default: true },
    swapHiddenInput: { type: Boolean, default: false },
    numeralDecimalScale: { type: Number, default: 0 },
    prefix: { type: String, default: "" },
    tailPrefix: { type: Boolean, default: false },
    rawValueTrimPrefix: { type: Boolean, default: false },
  };

  connect() {
    super.connect();
    this.setCleave();
  }

  setCleave() {
    new Cleave(this.element, this.options);
  }

  get options() {
    return {
      numeral: this.numeralValue,
      numeralDecimalScale: this.numeralDecimalScaleValue,
      prefix: this.prefixValue,
      tailPrefix: this.tailPrefixValue,
      rawValueTrimPrefix: this.rawValueTrimPrefixValue,
      swapHiddenInput: this.swapHiddenInputValue,
      delimiter: this.delimiterValue,
    };
  }
}
