import ApplicationController from "./application_controller";
// Modal that renders after attaching images that allows user to select image_type.
export default class extends ApplicationController {
  static targets = [ 
    "submitBtn",
    "checklistContainer",
    "checklistItem", 
    "checklistNumberLeftContainer",
    "checklistNumberLeft",
    "checklistImagesWord"
  ]
  static values = {
    newPage: Boolean,
    bedroomsNumber: Number,
    bathroomsNumber: Number,
  }

  initialize() {
    this.input = document.getElementById('property-images-bulk-upload'); //? Grab file input
    this.uploadingCompleteMsg = "All mandatory property images have been selected. You can now press Next to progress to the last and final step!"; //? Message to show at end when everything is uploaded.
    this.isValid = false; //? Boolean to check if all mandatory images have been selected
    this.files = this.input.files; //? Grab files from file input
    this.selectedFields = []; //? Store what images have been selected in an array
    this.selectedMandatoryImagesNumber = 0; //? Number of mandatory images selected
    this.mandatoryNumberOfImages = 12; //? Number of mandatory images
    if (this.newPageValue == false) this.uploadingCompleteMsg = "All mandatory property images have been selected. You can now press Update to save the property images";  //? Change complete message since we're on media update page
  }
  
  connect() {
    super.connect();

    this.unselectedImagesNumber = this.element.querySelectorAll('select[data-has-selected="false"]').length;
    this.updateSelectedOptionsArray();
    this.hideSelectOptions();
    this.validateMandatoryImages(false);
    this.updateChecklist();
  }

  resetUpload(e) {
    super.renderConfirmModal(
      e.target, 
      '',
      '', 
      "This will reset the images you have attached and return to the upload form.",
      `Reset uploads and images`,
      "info",
      "Yes",
      "Are you sure?",
      "Nevermind",
      false,
      {"reflex": "click->PropertyFormImageSelectReflex#reset_upload", "uuid": `${e.target.dataset.propertyUuid}`, "action": `click->modal--modal#closeModal`}
    );
  }

  assignImageType(e) {
    const selectField = e.target;
    const selectFieldValue = selectField.value;
    const selectFieldId = selectField.dataset.id;
    const imageSelectorElement = this.element.querySelector(`article[data-id="${selectFieldId}"`);

    this.stimulate("PropertyFormImageSelectReflex#update_image_type", selectField)
    this.updateSelectedOptionsArray();
    this.updateChecklist();    
    
    //? User a selected image value like 'Property Back' and then went to placeholder option, 'Select Image Label'
    selectFieldValue == "" ?
        imageSelectorElement.classList.remove('has-selected')
      :
        imageSelectorElement.classList.add('has-selected');
    
    if (selectField.dataset.hasSelected == "false") {
      this.unselectedImagesNumber -= 1;
    }
    if (selectField.dataset.hasSelected == "true" && selectFieldValue == "") {
      this.unselectedImagesNumber += 1;
    }
    
    this.updateSelectOptions(selectField);

    selectField.setAttribute('data-has-selected', selectFieldValue != "");

    this.renderImageGroupSelect(selectField);
    this.validateMandatoryImages();
    this.updateChecklistNumbers();
  }

  // Renders the image_group_component if bedroom or bathroom is selected
  renderImageGroupSelect(selectField) {
    if (selectField.value == "Bedrooms") {
      if (this.bedroomsNumberValue == 0) return;
      return this.stimulate(
        "PropertyFormImageSelectReflex#render_image_group_input", 
        selectField, 
        this.bedroomsNumberValue,
        "Bedroom"
      )
    }
    if (selectField.value == "Bathrooms") {
      if (this.bathroomsNumberValue == 0) return;
      return this.stimulate(
        "PropertyFormImageSelectReflex#render_image_group_input", 
        selectField, 
        this.bathroomsNumberValue,
        "Bathroom"
      )
    }
    
    const imageGroupSelector = document?.getElementById(`image-group-select-container-${selectField.dataset.id}`);
    if (imageGroupSelector) imageGroupSelector.remove();
  }

  //? Modifies the single source of truth array that keeps track of what has been selected
  updateSelectedOptionsArray() {
    const selectedOptions = this.element.querySelectorAll('.image-type-selector option:checked:not([value=""])');
    this.selectedFields = [...selectedOptions];
  }

  //? Handles the visibility of options inside select fields
  updateSelectOptions(selectField) {
    if (selectField.dataset.hasSelected == "true") {
      this.showSelectOptions();
    }
    this.hideSelectOptions();
  }

  //? Hides the select options that are already used and singular
  hideSelectOptions() {
    this.selectedFields.forEach(item => { 
      this.element.querySelectorAll(`.image-type-selector option.${item.dataset.name}[data-single-image="true"]:not([value=""])`).forEach(option => {
        option.classList.add('vanish');
      })
    })
  }

  //? Shows the select options that are not yet used and singular
  showSelectOptions() {
    this.element.querySelectorAll(`.image-type-selector option[data-single-image="true"]:not([value=""])`).forEach(option => {
      option.classList.remove('vanish');
    })
  }

  //? Updates checklist SVGs and CSS based on what images have been selected
  updateChecklist() {
    this.checklistItemTargets.forEach(item => {
      item.classList.remove('is-selected');
    })
    this.selectedFields.forEach(item => {
      this.checklistContainerTarget.querySelector(`#checklist-item-${item.dataset.name}`).classList.add('is-selected');
    })
  }

  //? Updates the bottom of the checklist with the number of images that have been selected
  updateChecklistNumbers() {
    if (this.unselectedImagesNumber != 0) {
      this.checklistNumberLeftContainerTarget.classList.remove('vanish', 'animate-fade-out');
      //? Singularize or pluralize the word 'image'
      this.checklistImagesWordTarget.textContent = this.unselectedImagesNumber == 1 ? 'image' : 'images';
      this.checklistNumberLeftTarget.textContent = this.unselectedImagesNumber;
      return;
    }
    this.checklistNumberLeftContainerTarget.classList.add('animate-fade-out')
  }

  //? If valid, enable submit button, which will also show flash message and remove checklist number left message, otherwise disable submit button
  checkValidityToUpdatePage(showToastMessage = true) {
    if (this.isValid) {
      return this.enableSubmitBtn(showToastMessage);
    }
    
    this.disableSubmitBtn();
  }

  disableSubmitBtn() {
    this.submitBtnTarget.setAttribute('disabled', "disabled");
  }

  enableSubmitBtn(showToastMessage = true) {
    this.submitBtnTarget.removeAttribute('disabled'); 
    if (showToastMessage) super.renderFlash(
      this.uploadingCompleteMsg, 
      "notice", 
      "true"
    ); 
  }
  
    //? Determines if next step is ready and images are all valid
  validateMandatoryImages(showToastMessage = true) {
    this.isValid = false;
    //? Take existing selectedFields and turn into array of value strings (['front_image', 'backyard_image'], etc)      
    this.selectedMandatoryValues = this.selectedFields.map(selected => {
      switch (selected.dataset.name) {
        case "images":
        case "appliance_images":
        case "damage_images":
          return;
        default:
          return selected.value;
      }
    }).filter(value => value != undefined); //? Remove images, appliance_images, and damage_images from array
            
    //? Remove duplicates like bedroom_images and bathroom_images to ensure they're only counted once for validation as only 1 is needed
    this.selectedMandatoryValues = Array.from(new Set(this.selectedMandatoryValues)) 
    //? Update number of mandatory images that have already been selected
    this.selectedMandatoryImagesNumber = this.selectedMandatoryValues.length;

    //? Ensure all mandatory images have been selected, number of images isn't above max and all image previews have been selected, so next button is valid
    if (this.selectedMandatoryImagesNumber == this.mandatoryNumberOfImages && this.unselectedImagesNumber == 0) this.isValid = true; 
    

    this.checkValidityToUpdatePage(showToastMessage);
    return this.isValid;
  }
}