import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["unchecker", "uncheckable"];

  uncheckAll(event) {
    if (event.target.checked) {
      this.uncheckableTargets.forEach((target) => {
        if (target.checked) {
          target.checked = false;
          this.dipatchChangeEvent(target);
        }
      });
    }
  }

  setUnchecker() {
    // If any of the uncheckable targets are checked, then uncheck the unchecker
    const anyChecked = this.uncheckableTargets.some((target) => {
      return target.checked;
    });

    this.uncheckerTarget.checked = !anyChecked;
  }

  dipatchChangeEvent(target) {
    const changeEvent = new Event("change", { bubbles: true });
    target.dispatchEvent(changeEvent);
  }
}
