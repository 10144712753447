import ApplicationController from "./application_controller";
import Cleave from "cleave.js";

export default class extends ApplicationController {
  static targets = [ "contactNumberInput" ]

  initialize() {
    new Cleave(this.contactNumberInputTarget, {
      delimiter: '-',
      blocks: [3, 3, 4],
      swapHiddenInput: true,
      numerical: true,
      numericOnly: true,
    });    
  }
}