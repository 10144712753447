import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
static targets = [ "searchInput" ]
static values = {
  reflex: String
}

  clearSearchInput() {
    this.searchInputTarget.value = "";
    this.stimulate(`${this.reflexValue}`, this.searchInputTarget)
  }
}