import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  create_rating_comment(e) {
    e.preventDefault();

    super.closeModal();
    
    setTimeout(() => {
      this.stimulate("BuyerCommentReflex#create_rating_comment");
    }, 400);
  }
}