import ApplicationController from "./application_controller";
import {listenForEsc} from "../../components/design/modal/modal_controller";
import { fetchUrl } from "../utilities/fetch_url";

export default class extends ApplicationController {
  static values = { videoUrl: String }

  fetchVideoUrl() {
    fetchUrl(this.videoUrlValue);
    listenForEsc();
  }
}