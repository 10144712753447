import ApplicationController from "./application_controller";

import { fetchUrl } from "../utilities/fetch_url";
export default class extends ApplicationController {
  static targets = [ 
    "buyBoxNotesDocs", 
    "buyBoxDetails", 
    "toggleNotesBtn", 
    "innerLink"
  ]
  static values = { 
    videoUrl: String, 
    isViewingDetails: Boolean, 
    isSelectable: Boolean
  }

  initialize() {
    if (this.isSelectableValue) {
      this.innerLinkTargets.forEach(link => {
        this.stopBubbleClick(link);
      })
    }
  }

  stopBubbleClick(element) {
    element.addEventListener("click", (e) => {
      if (!e) var e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
    })
  }

  toggleNotesDocs() {
    super.handleFadeAndVanish(this.buyBoxNotesDocsTarget, 300, this.isViewingDetailsValue == "true");
    this.updateToggleBtn(this.isViewingDetailsValue);
  }

  toggleVanish() {
    super.toggleVanish(this.buyBoxNotesDocsTarget);
    super.toggleVanish(this.buyBoxDetailsTarget);
  }

  updateToggleBtn(isDetails) {
    let btnText = "View Notes & Documents";
    this.isViewingDetailsValue = !isDetails;
    if (isDetails) btnText = "View Property Details";
    this.toggleNotesBtnTarget.textContent = btnText;
  }

  showBuyerVideo() {
    fetchUrl(this.videoUrlValue)
  }
}