import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ 
    "conditionallyRequiredInput", 
    "hoaFieldsContainer", 
    "viewedPropertyContainer", 
    "hoaFeesInput", 
    "hoaSelectInput", 
    "managedByInputsContainer",
    "currentlySTRInputsContainer"
  ]

  initialize() {
    if (this.hasHoaSelectInputTarget) this.toggleHoaFieldsVisibility(null, this.hoaSelectInputTarget);
  }

  updateRequiredFields(e) {
    this.conditionallyRequiredInputTargets.forEach(input => {
      if (e.target.value == "true") {
        input.setAttribute("required", true);
        super.removeVanish(this.managedByInputsContainerTarget);
        this.currentlySTRInputsContainerTarget.classList.remove("col-span-full");
      } else  {
        input.removeAttribute("required");
        super.addVanish(this.managedByInputsContainerTarget);
        this.currentlySTRInputsContainerTarget.classList.add("col-span-full");
      }
    })
  }

  toggleHoaFieldsVisibility(e, input = null) {
    if (e?.target?.value == "true" || input?.value == "true") {
      super.removeVanish(this.hoaFieldsContainerTarget);
      this.viewedPropertyContainerTarget.classList.remove("col-span-full");
      this.hoaFeesInputTarget.setAttribute("required", true);
      return;
    }
    super.addVanish(this.hoaFieldsContainerTarget);
    this.viewedPropertyContainerTarget.classList.add("col-span-full");
    this.hoaFeesInputTarget.removeAttribute("required");
  }
}