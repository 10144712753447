import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  connect() {
    super.connect();
    if (super.countToasts() > 1) {
      document.querySelectorAll('.toast-component').forEach((toast, i) => {
        toast.style.marginRight = `${i}rem`;
        toast.style.marginBottom = `${i}rem`;
      });
    }
  }

  dismissToast() {
    this.element.remove();
  }
}

