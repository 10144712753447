import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["vanishContainer"]

  static values = {focusEl: Boolean}

  addVanish() {
    super.addVanish(this.returnTargetOrControllerEl());
  }

  removeVanish() {
    super.removeVanish(this.returnTargetOrControllerEl());
    if (this?.hasFocusElValue == true ) {
      super.focusFirstEl(this.element);
    }
  }

  toggleVanish() {
    super.toggleVanish(this.returnTargetOrControllerEl());
  }

  toggleVanishOnThis(e) {
    super.toggleVanish(e.target);
  }

  returnTargetOrControllerEl() {
    return this.hasVanishContainerTarget ? this.vanishContainerTarget : this.element;
  }
}