import ApplicationController from "./application_controller";
import Cleave from "cleave.js";

export default class extends ApplicationController {
  static targets = [ "phoneInput", 'zipInput' ]

  initialize() {
    new Cleave(this.phoneInputTarget, {
      delimiter: '-',
      blocks: [3, 3, 4],
      swapHiddenInput: true,
      numerical: true,
      numericOnly: true,
    });    
    if (this.hasZipInputTarget) {
      new Cleave(this.zipInputTarget, {
        delimiter: ', ',
        blocks: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
        numerical: true,
        numericOnly: true,
      });
    }
  }
}