import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { message: String, title: String, btnText: String, footerMsg: String}
  
  confirmAction(e) {
    e.preventDefault();
    super.renderConfirmModal(
      e.target, 
      this.urlValue, 
      this.nameValue, 
      this.footerMsgValue,
      this.messageValue,
      "info",
      "Yes",
      "Are you sure?",
      "Nevermind",
      false,
      {"reflex": "click->OfferButtonsReflex#handle_offer", "method": `${e.target.dataset.method}`, "message": `${e.target.dataset.message}`}
    );
  }
}