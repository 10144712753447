import ApplicationController from "./application_controller";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export default class extends ApplicationController {
  static targets = ["buttonText", "expandIcon", "shrinkIcon"];
  static values = {
    newOffersCount: Number,
    presentedOffersCount: Number,
    acceptedOffersCount: Number,
    rejectedOffersCount: Number,
    revokedOffersCount: Number,
    expiredOffersCount: Number,
    soldOffersCount: Number,
    fellThroughOffersCount: Number,
    shouldRender: Boolean,
    enlarged: Boolean
  };

  initialize() {
    am4core.addLicense("CH250177190");
    am4core.useTheme(am4themes_animated);
    this.chartElement = document.getElementById("chart-container");
  }

  connect() {
    if (this.shouldRenderValue) renderChart(
      this.newOffersCountValue,
      this.presentedOffersCountValue,
      this.acceptedOffersCountValue,
      this.rejectedOffersCountValue,
      this.revokedOffersCount,
      this.expiredOffersCountValue,
      this.soldOffersCountValue,
      this.fellThroughOffersCountValue
    );
  }

  disposeChart() {
    disposeChart();
  }

  disconnect() {
    this.disposeChart();
  }

  enlarge() {
    let text;
    if (this.element.dataset["isEnlarged"] == "false") {
      text = "Shrink"
      this.element.dataset["isEnlarged"] = "true"
    } else {
      text = "Enlarge";
      this.element.dataset["isEnlarged"] = "false"
    }
    this.updateEnlargeBtn(text)
  }

  updateEnlargeBtn(text) {
    this.buttonTextTarget.textContent = text;
    this.chartElement.classList.toggle("enlarge");
    this.expandIconTarget.classList.toggle("vanish");
    this.shrinkIconTarget.classList.toggle("vanish");
    setTimeout(() => {

      this.element.blur();
    }, 200);
  }
}

function disposeChart() {
  am4core.disposeAllCharts();
}

export async function renderChart(newCount, presentedCount, acceptedCount, rejectedCount, revokedCount, expiredCount, soldCount, fellThroughCount) {
  disposeChart();
  // Create chart instance
  var chart = am4core.create("chart-container", am4charts.PieChart3D);
  const radiusValue = "1";
  var pieChart = chart.series.push(new am4charts.PieSeries3D());
  am4core.options.autoSetClassName = true;
  chart.innerRadius = am4core.percent(40);
  chart.hiddenState.properties.opacity = 0;
  chart.data = [
    {
      offerType: "New",
      value: newCount,
      radius: radiusValue,
      color: am4core.color("#F5821F"),
    },
    {
      offerType: "Presented",
      value: presentedCount,
      radius: radiusValue,
      color: am4core.color("#DFA906"),
    },
    {
      offerType: "Accepted",
      value: acceptedCount,
      radius: radiusValue,
      color: am4core.color("#8F31D9"),
    },
    {
      offerType: "Rejected",
      value: rejectedCount,
      radius: radiusValue,
      color: am4core.color("#dc3545"),
    },
    {
      offerType: "Revoked",
      value: revokedCount,
      radius: radiusValue,
      color: am4core.color("#dc3545"),
    },
    {
      offerType: "Expired",
      value: expiredCount,
      radius: radiusValue,
      color: am4core.color("#dc3545"),
    },
    {
      offerType: "Sold",
      value: soldCount,
      radius: radiusValue,
      color: am4core.color("#28A745"),
    },
    {
      offerType: "Fell Through",
      value: fellThroughCount,
      radius: radiusValue,
      color: am4core.color("#dc3545"),
    }
  ];
  // Configure pie options
  chart.depth = 20;
  chart.legend = new am4charts.Legend();
  chart.radius = am4core.percent(85);
  pieChart.slices.template.cornerRadius = 5;
  pieChart.colors.step = 3;
  pieChart.dataFields.value = "value";
  pieChart.dataFields.category = "offerType";
  pieChart.dataFields.radiusValue = "radius";
  pieChart.dataFields.depthValue = "value";
  pieChart.slices.template.propertyFields.fill = "color";
  pieChart.slices.template.stroke = am4core.color("#ddd");
  pieChart.slices.template.clickable = false;
  pieChart.slices.template.focusable = false;
  pieChart.slices.template.hoverOnFocus = true;
  pieChart.labels.template.maxWidth = 130;
  pieChart.labels.template.wrap = true;
  // Tooltips, labels and ticks on inner circle
  pieChart.tooltip.background.fill = am4core.color("#19469d");
  pieChart.tooltip.getFillFromObject = false;
  pieChart.labels.template.disabled = false;
  pieChart.ticks.template.disabled = false;
  pieChart.labels.template.text = "{category} \n[bold]{value.value}[/b]";
  pieChart.labels.template.bent = true

  pieChart.slices.template.states.getKey("hover").properties.shiftRadius = 0.05;
  pieChart.slices.template.states.getKey("hover").properties.scale = 1.1;
  document.querySelector("[role='region']").removeAttribute('clip-path');
}
