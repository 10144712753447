import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    wizardStep: String,
    propertyUuid: String
  }

  renderRedirectWarningModal(e) {
    super.renderConfirmModal(
      e.target, 
      '',
      '', 
      "This will return you to the previous step and therefore you'll lose any current progress made on the form here.",
      `Navigate back to step ${this.wizardStepValue}`,
      "warning",
      "Previous Step",
      "Are you sure?",
      "Nevermind",
      false,
      {"reflex": "click->PropertiesStepperReflex#render_previous_step", "property-form-previous-wizard-step-value": `${this.wizardStepValue}`, "uuid": `${this.propertyUuidValue}`, "action": `click->modal--modal#closeModal`}
    );
  }
  
  renderGraphicStepperRedirectWarningModal(e){
    super.renderConfirmModal(
      e.target, 
      '',
      '', 
      `This will return you to step ${this.wizardStepValue} and therefore you'll lose any current progress made on the form here.`,
      `Navigate back to step ${this.wizardStepValue}`,
      "warning",
      `Go to Step ${this.wizardStepValue}`,
      "Are you sure?",
      "Nevermind",
      false,
      {"reflex": "click->PropertiesStepperReflex#render_form_step", "step": `${this.wizardStepValue}`, "uuid": `${this.propertyUuidValue}`, "action": `click->modal--modal#closeModal`}
    );
    
  }

}