import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    'input', 
    'container', 
    'filePreview', 
    'imagePreview'
  ];
  static values = {
    maxImages: Number,
    minImages: Number
  }

  connect() {
    super.connect();
    this.inputId = this.inputTarget.id;
    this.validFormats = "PNG, JPG, JPEG, HEIC, HEIF";
    this.validFormatsArray = ["image/png, image/jpeg, image/jpg, image/heic, image/heif"];
    this.ifValidEnableSubmitValue = true;
    this.submitBtn = document.querySelector('input[type="submit"]') || document.querySelector('button[type="submit"]');
  }

  styleDragover() {
    this.containerTarget.classList.add("is-dragging");
  }

  styleDrop() {
    this.containerTarget.classList.remove("is-dragging");
    setTimeout(() => {
      if (this.inputTarget.files[0]) {
        this.containerTarget.classList.add("is-dropped"); 
        return;
      }
      this.containerTarget.classList.remove("is-dropped"); 
    }, 150);
  }

  styleStopDrag() {
    this.containerTarget.classList.remove("is-dragging");
  }

  addFile() {
    const files = [...this.inputTarget.files];
    let hasLargeFile = false;
    let skipFileCheck = false;
    let isValid = false;

    super.dismissToast(this.inputId); // First dismiss existing toasts relating to file uploads

    this.clearFilePreview();
    
    if (this.checkMaxFileNumber(files.length) || this.checkMinFileNumber(files.length)) {
      this.cleanupInputAndRenderToast(this.numberValidationMsg);
      isValid = false;
      skipFileCheck = true;
      if (this.ifValidEnableSubmitValue) this.submitBtn?.setAttribute('disabled', 'disabled')
    }

    if (skipFileCheck) return; // Break out since max number or mandatory number of files validation failed.

    files.every((file) => {
      if (this.hasInvalidFile(file.type)) {
        isValid = false;
        this.cleanupInputAndRenderToast(`Only ${this.validFormats} files can be uploaded. Please try again and upload only these file types.`)
        return false;
      }

      this.renderImagePreview(file);
      
      this.renderFileName(file.name);
      
      hasLargeFile = this.checkAttachedFileSize(file);
      
      isValid = true;
      return true;
    });
    this.removeUpdateImageLabelBtnIfPresent(); 
    if (isValid && this.ifValidEnableSubmitValue) this.submitBtn?.removeAttribute('disabled');
    if (hasLargeFile) super.renderToast(
      this.inputId, 'Since a large file is attached, uploading may take a bit of time to complete. <br> Please be patient.', 'info'
      );
    }
    
  //? If update image labels button is there, on media update page, remove it.
  removeUpdateImageLabelBtnIfPresent() {
    document?.querySelector("#update-image-labels-btn")?.remove();
  }
    
  renderFileName(fileName) {
    this.filePreviewTarget.insertAdjacentHTML('beforeend', `<div>${fileName}</div>`)
  }

  renderImagePreview(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      if (file.type == 'image/heif' || file.type == 'image/heic' || file.name.includes("heic") || file.name.includes("heif")) {
        let imageUUID = crypto.randomUUID();
        this.stimulate("ApplicationReflex#render_spin_loader", ".image-preview", imageUUID, "flex justify-center items-center")
        
        const formData = new FormData();
        // formData.append("image", file);
        formData.append("image", file, {
          contentType: 'image/heic',
        });
        formData.append("imageUUID", imageUUID);
        
        fetch("/convert_heic", {
          method: 'post',
          body: formData
        }).then(response => {
          response.blob().then(imgBlob => {
            let blobURL = URL.createObjectURL(imgBlob);
            this.imagePreviewTarget.insertAdjacentHTML('beforeend', `<img src='${blobURL}'>`);
            super.dismissToast("toast-component-image-preview");
          }).catch(e => {
            console.error(e)
            super.renderFlash("Something went wrong. Please contact support", "alert")
          })
        })
      } else {
        this.imagePreviewTarget.insertAdjacentHTML('beforeend', `<img src='${e.target.result}'>`);
      }
    };
    reader.readAsDataURL(file);
    this.element.classList.add('has-images');
  }

  checkAttachedFileSize(file) {
    return file.size > 1000000;
  }

  checkMaxFileNumber(fileLength) {
    if (fileLength > this.maxImagesValue) {
      this.numberValidationMsg = `Exceeded max number of files that can be attached! Please try again with ${this.maxImagesValue} or less files attached.`;
      return true;
    };
  }

  checkMinFileNumber(fileLength) {
    if (this.minImagesValue > fileLength) {
      this.numberValidationMsg = `Attached less files than necessary! Please try again with ${this.minImagesValue} or more files attached.`;
      return true;
    }
  }

  clearInputValue() {
    this.inputTarget.value = ''; 
  }

  cleanupInputAndRenderToast(message) {
    super.renderToast(
      this.inputId, message
    );
    this.clearInputValue();
    this.clearFilePreview();
  }

  clearFilePreview() {
    this.filePreviewTarget.textContent = "";
    this.element.classList.remove('has-images');
    this.imagePreviewTarget.textContent = "";
  }
  
  hasInvalidFile(fileType) {
    let isInvalidFormat = true;

    //? Loop to check against component's valid formats
    this.validFormatsArray.forEach((format) => {
      if (format.includes(fileType)) {
        isInvalidFormat = false;
      } 
    })
    return isInvalidFormat;
  }
}
