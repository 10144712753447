import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["buyboxtab", "buyboxcontent"]

  // Show more less functionality
  showMoreLess(event) {
      event.preventDefault();
      const button = event.currentTarget;
      const tabsContainerId = button.previousElementSibling.dataset.tabsContainerId;
      const tabsContainer = document.querySelector(`.buybox-tabs-container[data-tabs-container-id="${tabsContainerId}"]`);
      
      if (tabsContainer) {
          tabsContainer.classList.toggle('make-scrollable');

          const showMoreLessSpan = button.querySelector('#show-more-less-text');
          const showLessIcon = button.querySelector('#show-less-icon');
          const showMoreIcon = button.querySelector('#show-more-icon');
          const isScrollable = tabsContainer.classList.contains('make-scrollable');

          showMoreLessSpan.textContent = isScrollable ? 'Show Less' : 'Show More';

          button.title = isScrollable ? 'Click to view less details' : 'Click to view more details';
          showLessIcon.classList.toggle('vanish', !isScrollable);
          showMoreIcon.classList.toggle('vanish', isScrollable);

          tabsContainer.scrollTo({
            top: isScrollable ? 40 : 0,
            behavior: 'smooth'
          });
      }
  }

  // Changing the tab
  changeTab(event) {
    event.preventDefault();
    const selectedTab = event.currentTarget.dataset.tab

    this.buyboxtabTargets.forEach((tab) => {
      tab.classList.remove("active")
    })

    event.currentTarget.classList.add("active")

    this.buyboxcontentTargets.forEach((content) => {
      content.classList.toggle("active", content.dataset.tab === selectedTab);
    })
  }
}
