import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["sortFilter", "sortFilterOptions", "arrowIcon"];
  toggleSortFilterOptions() {
    this.sortFilterOptionsTarget.classList.toggle("appear");
  }

  hideSortFilterOptions(event) {
    const isArrowClicked = event.target.closest('.arrow-icon')
    const isOutsideSortFilterOptions = !this.sortFilterOptionsTarget.contains(event.target);
    const isInsideOptionItems = !this.sortFilterTarget.contains(event.target);

    if ((isOutsideSortFilterOptions || isArrowClicked) && isInsideOptionItems) {
      this.sortFilterOptionsTarget.classList.remove("appear");
      this.sortFilterOptionsTarget.classList.add("vanish");
    }
  }

  connect() {
    document.addEventListener("click", this.hideSortFilterOptions.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.hideSortFilterOptions.bind(this));
  }
}
