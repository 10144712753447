import ApplicationController from "./application_controller";
/* 
  ? When using this controller, ensure the markup has two data targets, one for min and one for max range. 
  ? Also ensure controller element has the formatted name you'd like to appear in the toast message.
  ? EG:
    ? Controller el: < data-input-validator-min-input-name-value="Min Offer Price" data-input-validator-max-input-name-value="Max Offer Price" >
    ? Max el:  < data: { action: "blur->input-validator#checkRangeValidity focus->input-validator#removeErrors", input_validator_target: "maxNumericRange" } >
*/
export default class extends ApplicationController {
  static targets = [ "minNumericRange", "maxNumericRange" ]
  static values = { minInputName: String, maxInputName: String}


  checkRangeValidity(e) {
    const minInputName = this.minInputNameValue;
    const maxInputName = this.maxInputNameValue;
    const inputId = e.target.id;
    this.minValue = Number(this.minNumericRangeTarget.value.replace(/,/g, ''));
    this.maxValue = Number(this.maxNumericRangeTarget.value.replace(/,/g, ''));
    this.maxInputId = this.maxNumericRangeTarget.id;
    this.minInputId = this.minNumericRangeTarget.id;

    //? Escape if user hasn't entered anything yet, or Toast is already up on screen.
    if (!this.maxValue || !this.minValue) return; 

    if (!this.validRange()) {
      e.target.parentElement.classList.add("has-errors"); 
      const message = `${maxInputName} cannot be less than ${minInputName}`;
      //? The ID passed both determines where to insert the HTML and for Toast Component ID to exist so no rerendering each time 
      super.renderToast(inputId, message); 
      return;
    } 

    this.removeParentsError(this.maxNumericRangeTarget);
    this.removeParentsError(this.minNumericRangeTarget);
    this.clearToasts();
  }

  removeErrors(e) {
    this.removeParentsError(e.target);
    if (this.validRange()) this.clearToasts();
  }

  clearToasts() {
    this.removeToast(this.maxInputId);
    this.removeToast(this.minInputId);
  }

  validRange() {
    if (this.minValue < this.maxValue) return true;
  }

  removeParentsError(element) {
    element.parentElement.classList.remove("has-errors");
  }

  removeToast(id) {
    super.dismissToast(`toast-component-${id}`)
  }
}