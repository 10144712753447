import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["starsContainer"];
  static values = {buyerName: String, isFormRendered: Boolean};

  rate(event) {
    const stars = [...this.starsContainerTarget.children];
    const totalStars = stars.length;
    const clickedStar = event.target;
    const index = stars.indexOf(clickedStar);

    this.count = totalStars - index;

    this.element.querySelector('.is-selected')?.classList.remove("is-selected");

    this.stimulate("BuyerRatingReflex#rate", this.count).then(() => {
        clickedStar.classList.add("is-selected");
        rated.textContent = `You have rated ${this.buyerNameValue} ${this.count} out of ${totalStars} stars`;
      })
      .then(() => { 
        if (this.isFormRenderedValue == false) this.stimulate("BuyerCommentReflex#render_form");
        this.isFormRenderedValue = true;
        }
      )
      .catch(() => this.stimulate("ApplicationReflex#render_flash", "Something went wrong.", "alert"));
  }
}
