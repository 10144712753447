import {checkMultiselectsValidity} from "../../components/design/forms/multiselect/multiselect_component_controller";

import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  checkSelectsValidity() {
    if (checkMultiselectsValidity()) super.renderToast(
          'buy-box-new-page',
          "Please select option(s) in the red highlighted form fields"
    );
  }
}
