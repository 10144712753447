import { Controller } from "@hotwired/stimulus";

// Keep track of required fields and enable/disable submit button
export default class extends Controller {
  static targets = [
    "validate",
    "required",
    "requiredConditional",
    "numeric",
    "telephone",
    "email",
    "flatpickr",
    "submit",
  ];
  static values = {
    allValid: { type: Boolean, default: false },
    disableSubmit: { type: Boolean, default: false },
  };

  connect() {
    this.allValidationErrors = {};

    this.setValidationTrigger();

    this.setInputListeners();

    this.formatNumericFields();

    this._formatTelephoneFields();
  }

  // Only validate form, and prevent next Stimulus action
  validateFormOnly(event) {
    this.validateForm();
    event.preventDefault();

    if (this._hasAnyErrors()) {
      event.stopImmediatePropagation();
    }
  }

  setValidationTrigger() {
    if (this.disableSubmitValue) {
      // This strategy just disables the submit button until all fields are valid
      this.validateFormAndEnableSubmit();
    } else {
      // This strategy shows validation errors after form submission
      // Once fields are dirty, validate them on change and on blur
      // Intercept form submission
      this.element.addEventListener("submit", (event) =>
        this.handleFormSubmit(event)
      );
    }
  }

  setInputListeners() {
    // For the numeric fields that allow non-numeric characters
    // thousand separators or suffixes like sq. ft., %, etc.
    this.numericTargets.forEach((numericField) => {
      if (numericField.dataset.allowNonNumeric) {
        numericField.addEventListener("keyup", (event) => {
          const formattedValue = this.formatNumericField(event.target);

          // Set the formatted value in the field
          event.target.value = formattedValue;

          // Place cursor at the end of number and before suffix
          const cursorPosition =
            event.target.value.length -
            (event.target.dataset.suffix.length + 1);

          event.target.setSelectionRange(cursorPosition, cursorPosition);
        });
      }
    });

    // For telephone fields, to format while typing
    this.telephoneTargets.forEach(telephoneField => {
      telephoneField.addEventListener("keyup", (event) => {
        const formattedValue = this._formatTelephoneField(event.target);

        // Set the formatted value in the field
        event.target.value = formattedValue;

        // Place cursor at the end of input value
        // const cursorPosition =
        //   event.target.value.length -
        //   (event.target.dataset.suffix.length + 1);
      })
    })
  }

  formatNumericFields() {
    this.numericTargets.forEach((numericField) => {
      if (numericField.dataset.allowNonNumeric) {
        const formattedValue = this.formatNumericField(numericField);

        // Set the formatted value in the field
        numericField.value = formattedValue;
      }
    });
  }

  formatNumericField(field) {
    const actualValue = this._numericFieldActualValue(field);

    // If the field is empty, skip formatting
    if (!actualValue) {
      return "";
    }

    // Add thousand separators if the field is not decimal
    // Currently there is no requirement for decimal field to have thousand separators
    const isDecimal = field.dataset.allowDecimal;
    let newValue = isDecimal
      ? actualValue
      : Number(actualValue).toLocaleString("en-US");

    // If the field has a suffix, add it back
    if (field.dataset.suffix) {
      newValue += ` ${field.dataset.suffix}`;
    }

    return newValue;
  }

  _formatTelephoneFields() {
    this.telephoneTargets.forEach((telephoneField) => {
      const formattedValue = this._formatTelephoneField(telephoneField);

      // Set the formatted value in the field
      telephoneField.value = formattedValue;
    });
  }

  _formatTelephoneField(telephoneField) {
    const actualValue = this._telephoneFieldActualValue(telephoneField);
    let newValue = actualValue;

    if (actualValue.length > 3 && actualValue.length <= 6) {
      newValue = actualValue.replace(/(\d{3})(\d+)/, '$1-$2');
    } else if (actualValue.length > 6) {
      newValue = actualValue.replace(/(\d{3})(\d{3})(\d+)/, "$1-$2-$3");
    }

    return newValue;
  }

  _telephoneFieldActualValue(telephoneField) {
    // Remove non digit characters
    return telephoneField.value.replace(/\D/g, "");
  }

  validateFormAndEnableSubmit() {
    this.validateForm();

    this.submitTarget.disabled = this._hasAnyErrors();
  }

  // Validate form fields and prevent form submission if there are any errors
  // If there are no errors, format fields
  handleFormSubmit(event) {
    this.validateForm();

    // If there are any validation errors, show them and prevent form submission
    if (this._hasAnyErrors()) {
      event.preventDefault();
    } else {
      this.prepareFormForSubmission();
    }
  }

  validateForm() {
    // Reset validation errors
    this.allValidationErrors = {};

    // Filter out hidden targets
    this.visibleValidateTargets = this.validateTargets.filter(
      (target) =>
        this.isElementVisible(target) || this.flatpickrTargets.includes(target)
    );

    this.visibleValidateTargets.forEach((field) => {
      // Validate field
      this.validateField(field);
    });
  }

  validateField(field) {
    // Clear any previous validation errors
    delete this.allValidationErrors[field.name];

    // Clear any previous validation messages
    this.clearValidationMessage(field);

    // Trim field value
    field.value = field.value.trim();

    // Validate required
    if (this.requiredTargets.includes(field)) {
      this.validateRequiredField(field);
    }

    // Validate numeric
    if (this.numericTargets.includes(field)) {
      this.validateNumericField(field);
    }

    // Validate email
    if (this.emailTargets.includes(field)) {
      this.validateEmailField(field);
    }
  }

  clearValidationMessage(field) {
    const fields = this.element.querySelectorAll(`[name="${field.name}"]`);

    fields.forEach((element) => {
      // Remove any previous validation messages
      if (element.classList.contains("is-invalid")) {
        element.classList.remove("is-invalid");
      }

      const validationMessage = element.parentElement.querySelector(
        ".validation-message"
      );

      if (validationMessage) {
        validationMessage.remove();
      }
    });
  }

  validateRequiredField(field) {
    // Validate radio button or checkbox
    if (field.type === "radio" || field.type === "checkbox") {
      this.validateRadioOrCheckboxField(field);
      return;
    }

    let fieldValue = field.value.trim();

    // If the field is numeric, remove any non-numeric characters
    if (this.numericTargets.includes(field)) {
      fieldValue = this._numericFieldActualValue(field);
    }

    // If the field is required only if a sibling field is empty, skip validation
    if (this.requiredConditionalTargets.includes(field)) {
      if (field.dataset.siblingInputId) {
        const siblingInput = document.getElementById(
          field.dataset.siblingInputId
        );

        const siblingValue = this._numericFieldActualValue(siblingInput);

        if (siblingValue && siblingValue > 0) {
          return;
        }
      }
    }

    // Validate other fields
    if (!fieldValue) {
      this.addValidationError(field, "Required");
    }
  }

  validateRadioOrCheckboxField(field) {
    // If the field is required conditionally
    if (this.requiredConditionalTargets.includes(field)) {
      // If the sibling field is checkbox and not checked, skip validation
      if (field.dataset.siblingInputId) {
        const siblingInput = document.getElementById(
          field.dataset.siblingInputId
        );

        if (siblingInput.type == "checkbox" && !siblingInput.checked) {
          return;
        }
      }
    }

    // Skip if we've already validated this group
    if (this.allValidationErrors[field.name]) {
      return;
    }

    // Validate radio button or checkbox group
    const radioGroup = this.element.querySelectorAll(
      `input[name="${field.name}"]`
    );

    let oneChecked = false;

    radioGroup.forEach((radio) => {
      if (radio.checked) {
        oneChecked = true;
      }
    });

    if (!oneChecked) {
      this.addValidationError(radioGroup[0], "Required");
    }
  }

  validateNumericField(numericField) {
    // Validating number fields
    // Allow non-numeric characters by setting data-allow-non-numeric="true"
    // Validate max value by setting data-max-value="100"
    // Validate min value by setting data-min-value="0"
    // Can have suffix like sq. ft., %, etc. by setting data-suffix="sq. ft."
    const actualValue = this._numericFieldActualValue(numericField);

    const numericValue = Number(actualValue);

    // If the field does not allow non-numeric characters, set the numeric value in the field
    if (!numericField.dataset.allowNonNumeric) {
      numericField.value = numericValue;
    }

    // If the field is empty, skip validation
    if (!numericField.value.trim()) {
      return;
    }

    if (isNaN(numericValue)) {
      this.addValidationError(numericField, "Must be a number");
    }

    // If the field has a max value, validate it
    if (numericField.dataset.maxValue) {
      if (numericValue > Number(numericField.dataset.maxValue)) {
        this.addValidationError(
          numericField,
          `Must be less than ${numericField.dataset.maxValue}`
        );
      }
    }

    // If the field has a min value, validate it
    if (numericField.dataset.minValue) {
      if (numericValue < Number(numericField.dataset.minValue)) {
        this.addValidationError(
          numericField,
          `Must be greater than ${numericField.dataset.minValue}`
        );
      }
    }
  }

  validateEmailField(emailField) {
    // Validate email fields
    const emailValue = emailField.value.trim();

    // If the field is empty, skip validation
    if (!emailValue) {
      return;
    }

    // Validate email format
    if (!this._isValidEmail(emailValue)) {
      this.addValidationError(emailField, "Invalid email address");
    }
  }

  addValidationError(field, message) {
    if (!this.allValidationErrors[field.name]) {
      this.allValidationErrors[field.name] = [];
    }

    this.allValidationErrors[field.name].push(message);
    this.markFieldAsDirty(field);

    if (!this.disableSubmitValue) {
      this.showValidationErrors(field, this.allValidationErrors[field.name]);
    }
  }

  showValidationErrors(field, messages) {
    // Add error class
    field.classList.add("is-invalid");

    // Add validation message
    const validationMessage = document.createElement("div");
    validationMessage.classList.add("validation-message");
    validationMessage.classList.add("text-danger");
    validationMessage.classList.add("text-sm");
    validationMessage.textContent = messages.join(", ");

    field.parentElement.appendChild(validationMessage);
  }

  // If field gets validation error, mark is as dirty
  // Validate the dirty fields on onchange and onblur
  markFieldAsDirty(field) {
    // Fields with same name
    const fields = this.element.querySelectorAll(`[name="${field.name}"]`);

    fields.forEach((element) => {
      if (element.classList.contains("is-dirty")) {
        return;
      }

      // Add dirty class
      element.classList.add("is-dirty");

      // Set listener for onchange and onblur
      element.addEventListener("change", (event) =>
        this.validateField(event.target)
      );

      element.addEventListener("blur", (event) =>
        this.validateField(event.target)
      );
    });
  }

  isElementVisible(element) {
    // If element is radio or checkbox, return true
    // As we are using hidden radio and checkbox fields
    if (element.type === "radio" || element.type === "checkbox") {
      return true;
    }

    return element.offsetParent !== null;
  }

  prepareFormForSubmission() {
    // Format numeric fields
    this.numericTargets.forEach((numericField) => {
      numericField.value = this._numericFieldActualValue(numericField);
    });

    // For telephone fields
    this.telephoneTargets.forEach(telephoneField => {
      telephoneField.value = this._telephoneFieldActualValue(telephoneField);
    })
  }

  _numericFieldActualValue(numericField) {
    let actualValue = numericField.value;

    // Remove suffix
    if (numericField.dataset.suffix) {
      actualValue = actualValue.replace(` ${numericField.dataset.suffix}`, "");
    }

    // Remove any non-numeric characters leave only numbers or decimal
    const isDecimal = numericField.dataset.allowDecimal;
    actualValue = isDecimal ? actualValue.replace(/[^0-9.]/g, "") : actualValue.replace(/[^0-9]/g, "");

    return actualValue;
  }

  _hasAnyErrors() {
    return Object.keys(this.allValidationErrors).length > 0;
  }

  _isValidEmail(email) {
    // Email regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  }
}
