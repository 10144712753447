import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = [ 
    "accordion", 
    "toggleBtnText", 
    "toggleBtn" 
  ]
  static values = { 
    closeText: String,
    openText: String,
    isOpen: Boolean
  }

  initialize() {
    if (this.isOpenValue) this.setAccordionHeight();
  }

  toggleOpen(e) {
    this.isOpenValue = !this.isOpenValue;
    if (this.accordionTarget.style.maxHeight) {
      this.accordionTarget.style.maxHeight = null;
      setTimeout(() => {
        this.element.classList.remove("is-open");
      }, 250)
    } else {
      this.element.classList.add("is-open");
      this.setAccordionHeight();
    }
    this.toggleBtnTextTarget.textContent = this.isOpenValue ? this.closeTextValue : this.openTextValue;
    this.updateAccessibility();
  }
  
  setAccordionHeight() {
    this.accordionTarget.style.maxHeight = `${this.accordionTarget.scrollHeight}px`;
  }

  updateAccessibility() {
    this.toggleBtnTarget.setAttribute("aria-expanded", this.isOpenValue);
    this.accordionTarget.setAttribute("aria-hidden", !this.isOpenValue);
  }
}