import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  static targets = ["likeButton"]

  likeMessage() {
    this.stimulate("LikesReflex#like_message", this.element)
      .then(() => {
        setTimeout(() => {
          this.likeButtonTarget.classList.add("animate-hands-up");
        }, 250)
      });
  }
}