console.log('Vite ⚡️ Rails')

import '@hotwired/turbo-rails'


import "trix"
import "@rails/actiontext"
import "../controllers"
import "../config"
import "../channels"
