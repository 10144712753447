export function limitInputChar(inputEl, charLimit) {  
  inputEl.addEventListener("keyup", function(e) {
    checkLength();
  })
  const checkLength = () => {
    if (inputEl.value.length >= (charLimit - 1)) {
      inputEl.value = inputEl.value.substring(0, charLimit);
    }
  }
}
