import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String, openInNewTab: Boolean }

  connect() {
    this.element.addEventListener("click", this.visitLink.bind(this));
  }

  visitLink(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.openInNewTabValue) {
      window.open(this.urlValue, "_blank");
    } else {
      Turbo.visit(this.urlValue);
    }
  }
}
