import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    name: String, 
    url: String, 
    message: String, 
    title: String, 
    confirmText: String,
    method: String
  }
  
  confirmRedirect(e) {
    e.preventDefault();
    super.renderConfirmModal(
      e.target, 
      this.urlValue, 
      this.nameValue, 
      this.hasMessageValue ? this.messageValue : "This will redirect you from this page and therefore you'll lose any progress made here.",
      this.hasTitleValue ? this.titleValue : `Navigate to ${this.nameValue}`,
      "warning",
      this.hasConfirmTextValue ? this.confirmTextValue : "Yes",
      "Are you sure?",
      "Nevermind",
      true,
      {},
      this.methodValue

    );
  }
}