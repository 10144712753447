import ApplicationController from "./application_controller";
import {fetchUrl} from "../utilities/fetch_url";

export default class extends ApplicationController {
  static values = { name: String, uuid: String, link: String }

  passOnProperty() {
    fetchUrl(this.linkValue);
  }

  unpassOnProperty(e) {
    super.renderConfirmModal(
      e.target,
      this.linkValue,
      this.nameValue,
      `This will allow you to make offers again on this property.`,
      `Are you sure you want to unpass on ${this.nameValue}?`,
      "info",
      "Yes",
      "Are you sure?",
      "Nevermind",
      true,
      {},
      "delete"
    );
  }

  markAsSold(e) {
    super.renderConfirmModal(
      e.target,
      this.linkValue,
      this.nameValue,
      `This will mark this property as sold and accept the current offer for <b>${this.nameValue}</b>.`,
      `This will accept the <a href="${this.linkValue}" class="b link">current offer</a>`,
      "info",
      "Yes",
      "Are you sure you want to mark as sold?",
      "Nevermind",
      false,
      {"reflex": "click->PropertyShowReflex#mark_as_sold", "uuid": `${this.uuidValue}`}
    );
  }
}
