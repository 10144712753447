// Allows dropdowns to be opened and dismissed
import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ["dropdownBtn", "dropdown", "dropdownMenu"];

  toggleDisplay() {
    this.dropdownTarget.classList.toggle("is-open");
    this.dropdownMenuTarget.classList.remove("animate-flip-out-x");
    this.dropdownMenuTarget.parentElement.classList.remove("animate-flip-out-x");
    if (!this.data.has("has-dismiss-click-listener"))
      this.listenForDismissClick(this.dropdownMenuTarget);
  }

  hide() {
    this.dropdownTarget.classList.remove("is-open");
  }

  listenForDismissClick(menu) {
    this.data.set("has-dismiss-click-listener", true);
    document.addEventListener("click", (e) => {
      if (!this.element.contains(e.target)) {
        menu.classList.add("animate-flip-out-x");
        setTimeout(() => {
          this.hide();
        }, 550);
      }
    });
  }

  disconnect() {
    this.hide();
  }
}
