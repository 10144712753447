import ApplicationController from "./application_controller";
// Allows the css classes for user based messaging styling to be handled properly.
// HAndles new messages notification.
// Then scrolls the chatroom into view if it's overflow.
export default class extends ApplicationController {
  connect() {
    const userDiv = document.getElementById('user-id');
    const userMessageId = this.element.dataset.userId;
    const currentUserId = userDiv.dataset.userId;
    const chatMessageContainer = this.element.parentElement;
    const newMessageSound = chatMessageContainer.querySelector('#new-message-sound');

    // Current user received the message sent
    if (userMessageId !== currentUserId) {
      this.element.classList.remove('is-users-message');
      newMessageSound.play(); // Plays new message ding to alert user of a new message.
      this.element.classList.add('animate-fade-in-from-left');
    } else { // Get rid of new message notification and like btn reflex action so user can't like own message
      const likeBtn = this.element.querySelector('.like-btn');
      likeBtn.removeAttribute('data-action');
      this.element.classList.add('animate-fade-in-from-left');
    }
    scrollMessage(this.element)
  }
}

function scrollMessage(message) {
  if (!document.hidden) message.scrollIntoView({behavior: 'smooth'})
}