import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "content"];

  switch(event) {
    const selectedTab = event.currentTarget;
    const tabName = selectedTab.dataset.tabName;

    // Update tabs
    this.tabTargets.forEach((tab) => {
      tab.classList.toggle("active");
      tab.classList.toggle("inactive");
    });

    // Show/hide content
    this.contentTargets.forEach((content) => {
      content.classList.toggle("appear");
      content.classList.toggle("vanish");
    });
  }
}
