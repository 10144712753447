import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  // Inject CSRF token into form and submit it
  // When having turbo: false on the form
  // Usage:
  // <form data-controller="form-submit" data-action="submit->form-submit#submitWithToken" data-form-id="form-id">
  submitWithToken(e) {
    window.onbeforeunload = null; // Remove any unload warnings
    e.preventDefault();
    const form = document.querySelector(`#${e.target.dataset.formId}`);

    if (form) {
      const token = document.querySelector('meta[name="csrf-token"]').content;
      const tokenInput = document.createElement("input");
      tokenInput.setAttribute("type", "hidden");
      tokenInput.setAttribute("name", "authenticity_token");
      tokenInput.setAttribute("value", token);
      form.appendChild(tokenInput);
      form.submit();
    } else {
      console.error("No form found");
    }
  }
}
