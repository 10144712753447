import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  update_offer_fell_through_reason(e) {
    e.preventDefault();

    super.closeModal();
    
    setTimeout(() => {
      this.stimulate("OfferButtonsReflex#update_offer_fell_through");
    }, 400);
  }
}