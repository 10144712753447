import { Controller } from "stimulus";

export default class extends Controller {
static targets = [ "video" ]

  //? Could make better by implementing eventlisteners on video to toggle button state, etc.
  toggleVideo(e) {
    if (this.videoTarget.dataset["isPlaying"] == "true" && !this.videoTarget.paused) {
      this.videoTarget.dataset["isPlaying"] = "false"
      e.target.textContent = "Play"  
      this.videoTarget.pause();
      return;
    }
    e.target.textContent = "Pause"
    this.videoTarget.play();
    this.videoTarget.dataset["isPlaying"] = "true"
  }
}