import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  deleteVideo(e) {
    super.renderConfirmModal(
      e.target, 
      this.urlValue, 
      this.nameValue, 
      "This will delete your current video on all your Buy Boxes",
      `Are you sure you want to delete your Buy Box video?`,
      "warning",
      "Yes",
      "Are you sure?",
      "Nevermind",
      false,
      {"reflex": "click->BuyerVideoReflex#delete_video"}
    );
  }

}