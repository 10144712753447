export function fetchUrl(url, selector = "main") {  
  const main = document.querySelector(selector)
  document.body.setAttribute("modal-open", true);
  fetch(url)
    .then((r) => r.text())
    .then((html) => {
      let fragment = document.createRange().createContextualFragment(html);
      main.appendChild(fragment);
    });
}
