import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["trigger", "toggleable", "secondToggleable"];
  static values = {
    toggleableShow: Array,
    secondToggleableShow: Array,
    toggleableRequired: { type: Boolean, default: false },
    secondToggleableRequired: { type: Boolean, default: false }
  };

  connect() {
    this.toggle();
  }

  toggle() {
    if (this.hasToggleableTarget) {
      // Determine if the toggleable element should be shown
      const shouldShow = this._shouldShow();

      if (shouldShow) {
        // Show the toggleable element
        this._show(this.toggleableTarget);

        // If the toggleable element is required, add the required attribute
        if (this.toggleableRequiredValue) {
          this._addRequired(this.toggleableTarget);
        }
      } else {
        // Hide the toggleable element
        this._hide(this.toggleableTarget);

        // If the toggleable element is required, remove the required attribute
        if (this.toggleableRequiredValue) {
          this._removeRequired(this.toggleableTarget);
        }
      }
    }

    // If there is a second toggleable target, determine if it should be shown
    if (this.hasSecondToggleableTarget) {
      const shouldShowSecond = this._shouldShowSecond();

      if (shouldShowSecond) {
        this._show(this.secondToggleableTarget);

        if (this.secondToggleableRequiredValue) {
          this._addRequired(this.secondToggleableTarget);
        }
      } else {
        this._hide(this.secondToggleableTarget);

        if (this.secondToggleableRequiredValue) {
          this._removeRequired(this.secondToggleableTarget);
        }
      }
    }
  }

  _shouldShow() {
    return this.triggerTargets.some((trigger) => {
      // For checkboxes, if the trigger for toogle is checked
      if (trigger.type === "checkbox") {
        return trigger.checked;
      }

      // For dropdowns
      // If the selected value is one of the values that should show the toggleable element
      return this.toggleableShowValue.includes(trigger.value);
    })
  }

  _shouldShowSecond() {
    // For dropdowns
    // For each trigger dropdown. There can be multiple dropdowns that trigger the toggleable element.
    // If any of the dropdowns have selected a whitelist value, show the toggleable element
    return this.triggerTargets.some((trigger) => {
      return this.secondToggleableShowValue.includes(trigger.value);
    })
  }

  _show(element) {
    super.removeVanish(element);
  }

  _hide(element) {
    const input = element.querySelector("input, select")
    input.value = "";
    super.addVanish(element);
  }

  _removeRequired(element) {
    element.querySelector("input, select").removeAttribute("required");
  }

  _addRequired(element) {
    element.querySelector("input, select").setAttribute("required", "required");
  }
}
