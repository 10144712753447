import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [ 
    "form", 
    'submitBtn'
  ];
  
  //? Hit directly from image_selector_modal after property images labeled
  closeModalAndRenderStepFive(e) {
    super.closeModal("#modal-component-property-image-selector", ".modal-card", ".modal-background", "animate-zoom-out", true);
    this.renderStepFive(e.target.dataset.propertyUuid);
  }
  
  renderStepFive(propUuid) {
    this.stimulate("PropertiesStepperReflex#render_step_five", propUuid).then(() => {
      document.getElementById("navbar-component").scrollIntoView({ behavior: "smooth" });
    });
  }

  //? Will hit on step four #new if Land
  onSubmitSuccess(e) {
    e.preventDefault();
    this.renderStepFive(e.detail[0].property.uuid);
  }
  
  onSubmitError(e) {
    const [data, _status, xhr] = e.detail;
    const formattedErrors =  data?.errors?.join("<br>")

    if (formattedErrors) super.renderFlash(
      `Please fix the errors listed below and try again. <br> <b> ${formattedErrors}</b>`, 
      "alert", 
      "true"
    );  
  }
}