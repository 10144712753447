import { Application } from "@hotwired/stimulus";
import consumer from "../channels/consumer";
import debounced from "debounced";

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
application.consumer = consumer;
window.Stimulus = application;
debounced.initialize({ input: { wait: 500 } });

const controllers = import.meta.globEager("../**/*_controller.js");
for (let path in controllers) {
  let module = controllers[path];
  let name = path
    .match(/\.\/(.+)_controller\.js$/)[1]
    .replace("controllers/", "")
    .replaceAll("/", "--")
    .replaceAll("_", "-");
  application.register(name, module.default);
}

const designControllers = import.meta.globEager(
  "../../components/design/**/*_controller.js"
);
for (let path in designControllers) {
  let module = designControllers[path];
  let name = path
    .match(/\.\/(.+)_controller\.js$/)[1]
    .replace("../components/design/", "")
    .replaceAll("/", "--")
    .replaceAll("_", "-");
  application.register(name, module.default);
}

import Flatpickr from "stimulus-flatpickr";

application.register("flatpickr", Flatpickr);

export { application };
